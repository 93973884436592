
































































































import { EventBus } from "@/components/EventBus";
import { SelectionDialogRequest, DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { Component, Vue } from "vue-property-decorator";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { VBtn, VCard, VDialog } from "vuetify/lib";

interface InternalItem {
    id: string;
    name: string;
    code?: string;
    description?: string;
    account?: string;

    // generic ordering
    order?: number;

    // organization specific
    organizationId?: string;
    showInSales?: boolean;
    showInPurchases?: boolean;

    // guide specific
    official?: boolean;

    // agency specific
    type?: string;

    // product specific
    zone?: string;
    routeCount?: number;

    // issues
    hasIssues?: boolean;
}
@Component
export default class extends Vue {
    dialog = false;

    type = "";
    organizationId = "";
    filter = "";
    search = "";

    title = "";
    message = "";
    warningMessage = "";
    warningMessageChecked = false;
    icon = "";
    iconColor = "";

    items: InternalItem[] = [];

    get filteredItems(): InternalItem[] {
        let base: InternalItem[] = [];

        base = this.items;

        if (this.search) {
            return base.filter((z) => Utilities.multiwordFilterWithoutDiacritics(z, this.search, ["name", "code", "description", "account"]));
        } else {
            return base;
        }
    }

    get placeholder(): string {
        switch (this.type) {
            case "agency": {
                return "Buscar en las agencias ...";
            }
            case "guide": {
                return "Buscar en los guías ...";
            }
            case "user": {
                return "Buscar en los usuarios ...";
            }
            case "employee": {
                return "Buscar en los empleados ...";
            }
            case "product": {
                return "Buscar en los productos ...";
            }
            default: {
                return "Buscar ...";
            }
        }
    }

    calculateTitle(): string {
        switch (this.type) {
            case "agency": {
                return "Seleccionar agencia";
            }
            case "employee": {
                return "Seleccionar empleado";
            }
            case "provider": {
                return "Seleccionar proveedor";
            }
            case "carrier": {
                return "Seleccionar transportista";
            }
            case "guide": {
                return "Seleccionar guía";
            }
            case "user": {
                return "Seleccionar usuario";
            }
            case "operator": {
                return "Seleccionar operador";
            }
            case "product": {
                return "Seleccionar producto";
            }
            default: {
                return "Seleccionar";
            }
        }
    }

    mounted() {
        EventBus.$on(DataClient.Dialogs.OpenSelectionDialogEvent, this.handleOpenEvent);
    }

    beforeDestroy() {
        EventBus.$off(DataClient.Dialogs.OpenSelectionDialogEvent, this.handleOpenEvent);
    }

    reset() {
        this.dialog = false;
        this.type = "";
        this.filter = "";
        this.search = "";

        this.title = "";
        this.organizationId = "";
        this.message = "";
        this.warningMessage = "";
        this.warningMessageChecked = false;
        this.icon = "";
        this.iconColor = "";
    }

    async handleOpenEvent(event: SelectionDialogRequest) {
        this.type = event.type;
        this.organizationId = event.organizationId || "";
        this.filter = event.filter || "";

        this.title = event.title || this.calculateTitle();
        this.message = event.message || "";
        this.warningMessage = event.warningMessage || "";
        this.icon = event.icon || "";
        this.iconColor = event.iconColor || "orange darken-2";

        await this.refresh();

        this.dialog = true;

        Utilities.focus(this, "searchbox");
    }

    accept(value: string) {
        if (this.warningMessage && !this.warningMessageChecked) {
            return;
        }

        DataClient.Dialogs.selection(value, true);
        this.reset();
    }

    cancel(fromEsc: boolean) {
        DataClient.Dialogs.selection("", true);
        this.reset();
    }

    async refresh() {
        switch (this.type) {
            case "employee": {
                this.items = await DataClient.Employees.getAll();
                break;
            }
            case "provider": {
                this.items = await DataClient.Providers.getAll();
                break;
            }
            case "carrier": {
                this.items = await DataClient.Carriers.getAll();
                break;
            }
            case "product": {
                const allProducts = await DataClient.Products.getAll();

                this.items = [];
                for (const product of allProducts) {
                    if (this.filter && product.type !== this.filter) {
                        continue;
                    }

                    this.items.push({
                        id: product.id,
                        code: product.code,
                        name: product.name,
                        description: product.activityName,
                        zone: product.zoneName,
                        routeCount: product.routesCount || 0,
                    });
                }
                break;
            }
            case "agency": {
                const allAgencies = await DataClient.Agencies.getAll();

                this.items = [];
                for (const agency of allAgencies) {
                    if (this.filter && agency.type !== this.filter) {
                        continue;
                    }

                    let organizationNames: string[] = [];

                    let found = false;

                    for (const organization of agency.organizations) {
                        organizationNames.push(organization.organizationName);

                        if (organization.organizationId === this.organizationId) {
                            found = true;
                            break;
                        }
                    }

                    if (this.organizationId) {
                        if (!found) {
                            continue;
                        }
                    }

                    this.items.push({
                        id: agency.id,
                        name: agency.name,
                        //scription: (agency.account || "Sin cuenta") + " / " + (agency.seriesName || "Sin serie de facturación") + (agency.organizationId ? " / " + agency.organizationName : ""),
                        description: (agency.account || "Sin cuenta") + " / " + organizationNames.join(", "),
                        //description: agency.seriesName,
                    });

                    if (agency.type === "ttoo") {
                        this.items[this.items.length - 1].type = "TTOO";
                    } else if (agency.type === "pos") {
                        this.items[this.items.length - 1].type = "PUNTO DE VENTA";
                    } else if (agency.type === "ota") {
                        this.items[this.items.length - 1].type = "OTA";
                    } else if (agency.type === "direct") {
                        this.items[this.items.length - 1].type = "DIRECTO";
                    } else if (agency.type === "business") {
                        this.items[this.items.length - 1].type = "EMPRESA";
                    } else if (agency.type === "affiliate") {
                        this.items[this.items.length - 1].type = "AFILIADO";
                    }
                }
                break;
            }
            case "guide": {
                this.items = await DataClient.Guides.getAll();

                for (const item of this.items) {
                    if (item.name.startsWith("*")) {
                        item.official = true;
                        item.name = item.name.substr(1);
                    }
                }

                break;
            }
            case "user": {
                const allUsers = await DataClient.Users.getAll();

                this.items = [];
                for (const user of allUsers) {
                    if (this.filter && user.type !== this.filter) {
                        continue;
                    }

                    this.items.push({
                        id: user.id,
                        name: user.name,
                        description: user.username,
                    });
                }

                break;
            }
            case "operator": {
                this.items = await DataClient.Operators.getAll();
                break;
            }
            default: {
                this.items = [];
            }
        }
    }

    onEnterKey() {
        const items = this.filteredItems;
        if (items.length === 1) {
            this.accept(items[0].id);
        }
    }
}
